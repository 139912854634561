import img1 from '../../../assets/images/comunicador-expressivo/calendar.svg'

const MeetingsSection = () => {
    return(
        <div className="container-fluid ptb--50 mx-0 px-0" id="comunicador-expressivo-meetings-section">
            <div className="container">
                <div className="row mb-5">
                    <h3>Nossos encontros serão nos dias:</h3>
                </div>
                <div className="d-flex align-items-center justify-content-evenly">
                    <div className="d-flex flex-column align-items-center justify-content-evenly">
                        <div className='image-box mb-3'>
                            <img src={img1} alt='icone calendario' />
                        </div>
                        <p className='text-center text-black mb-0 lh-sm fs-4'>05/10</p>
                        <p className='text-center text-black mb-0 lh-sm fs-4'>9h30 às 11h</p>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-evenly">
                        <div className='image-box mb-3'>
                            <img src={img1} alt='icone calendario' />
                        </div>
                        <p className='text-center text-black mb-0 lh-sm fs-4'>19/10</p>
                        <p className='text-center text-black mb-0 lh-sm fs-4'>9h30 às 11h</p>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-evenly">
                        <div className='image-box mb-3'>
                            <img src={img1} alt='icone calendario' />
                        </div>
                        <p className='text-center text-black mb-0 lh-sm fs-4'>26/10</p>
                        <p className='text-center text-black mb-0 lh-sm fs-4'>9h30 às 11h</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MeetingsSection;