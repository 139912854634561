import { useState } from "react"
import banner from '../../assets/images/banner.png'
import { FaTimesCircle } from "react-icons/fa";

const PopUp = () => {
  const [isPopup, setIsPopup ] = useState (true)

  function handlePopup(){
    setIsPopup(false)
  }

  return(
    <>
      {isPopup && (
        <div 
        className="container-fluid h-100 d-flex align-items-center justify-content-center" 
        style={{backgroundColor:'rgba(0,0,0,.8)', position:'fixed', top:0, left:0, zIndex:'998'}} onClick={handlePopup}>
          <div className="w-100 d-flex justify-content-center">
            <a href="https://academyabroad.com.br/descubra-o-idioma-ideal-com-a-academy-abroad" target="_blank" rel="noreferrer">
              <img src={banner} alt="popup semana do consumidor" style={{width:"100%", objectFit:'cover'}} />
            </a>
              <span style={{width:'0',position:'relative', right:'35px',top:'3px', zIndex:'999', cursor:'pointer'}}  onClick={handlePopup}><FaTimesCircle color="#00000095" style={{fontSize:'32px'}}/></span>
          </div>
        </div>
      )}
    </>
  )
}

export default PopUp